<template>
    <div style="width:100%;">
        <el-main>
            <el-container>
                <el-row>
                    <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                        <el-form :inline="true">
                            <el-input v-model="keyword" placeholder="请输入关键字查询" class="input-with-select" style="width:440px;margin-right:10px;">
                                <template #prepend>
                                    <el-select v-model="checkInput" multiple collapse-tags placeholder="请选择" style="width: 220px">
                                        <el-option label="科目名称" value="SubjectName"></el-option>
                                    </el-select>
                                </template>
                            </el-input>
                            <el-form-item label="">
                                <el-date-picker v-model="searchStartTime" type="date" placeholder="开始日期"></el-date-picker>
                                <el-date-picker v-model="searchEndTime" type="date" placeholder="结束日期"></el-date-picker>
                            </el-form-item>
                            <slot name="searchSlot"></slot>
                            <el-form-item>
                                <el-button type="primary" icon="el-icon-search" size="medium" @click="SearchPage">查询</el-button>
                                <el-button type="success" icon="el-icon-edit" v-has="'Add'" size="medium" @click="dialogVisible=true">新增</el-button>
                                <!-- <el-button type="warning" icon="el-icon-share" size="medium" @click="ExportExcel">导出</el-button> -->
                                <el-button type="danger" icon="el-icon-delete" v-has="'FakeDelete'" size="medium" @click="DeleteInfo">删除</el-button>
                                <!-- <el-popover placement="bottom" :width="400" trigger="click">
                                    <template #reference>
                                        <el-button><i class="el-icon-arrow-down el-icon-menu" /> 列筛选</el-button>
                                    </template>
                                    <el-checkbox-group v-model="dataTableSearchList">
                                        <el-checkbox checked style="display: block;margin:10px;" label="Id">Id主键</el-checkbox>
                                        <el-checkbox checked style="display: block;margin:10px;" label="SubjectName">科目名称</el-checkbox>
                                        <el-checkbox checked style="display: block;margin:10px;" label="SubjectIcon">科目Icon</el-checkbox>
                                        <el-checkbox checked style="display: block;margin:10px;" label="CreatedTime">创建时间</el-checkbox>
                                        <el-checkbox checked style="display: block;margin:10px;" label="UpdatedTime">更新时间</el-checkbox>
                                        <el-checkbox checked style="display: block;margin:10px;" label="CreatedUserId">创建者Id</el-checkbox>
                                        <el-checkbox checked style="display: block;margin:10px;" label="UpdatedUserId">修改者Id</el-checkbox>
                                        <el-checkbox checked style="display: block;margin:10px;" label="IsDeleted">软删除标记</el-checkbox>
                                        <el-checkbox checked style="display: block;margin:10px;" label="RemarkInfo">备注</el-checkbox>
                                        <el-checkbox checked style="display: block;margin:10px;" label="TenantOrgId">租户id</el-checkbox>

                                    </el-checkbox-group>
                                    <el-button size="small" type="text" @click="filterFunHandle('allchecked')">全选</el-button>
                                    <el-button size="small" type="text" @click="filterFunHandle('cancel')">取消全选</el-button>
                                </el-popover> -->
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                        <el-table size="small" ref="multipleTable" :max-height="heightStyle" border  :data="dataTable" :fit="true" @selection-change="handleSelectionChange">
                            <el-table-column type="selection" fixed="left" header-align="center" align="center" width="50"></el-table-column>
                            <el-table-column label="操作" fixed="right" header-align="center" align="center">
                                <template #default="scope">
                                    <el-button size="mini" v-has="'Update'" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                                    <el-button size="mini" v-has="'FakeDelete'" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                                </template>
                            </el-table-column>
                            <el-table-column sortable header-align="center" align="center" prop="SubjectName" label="科目名称">
                                <template #default="scope">
                                    <label>{{scope.row.SubjectName}}</label>
                                </template>
                            </el-table-column>
                            <el-table-column sortable header-align="center" align="center" prop="SubjectIcon" label="科目Icon">
                                <template #default="scope">
                                    <el-image :src="scope.row.SubjectIconUrl" :preview-src-list="[scope.row.SubjectIconUrl]" fit="fill" style="width: 90px; height: 100px"></el-image>
                                    <!-- <label>{{scope.row.SubjectIcon}}</label> -->
                                </template>
                            </el-table-column>
                            <el-table-column sortable header-align="center" align="center" prop="CreatedTime" label="创建时间">
                                <template #default="scope">
                                    <label>{{scope.row.CreatedTime}}</label>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-col>
                </el-row>
            </el-container>
            <el-footer style="height:60px;position:fixed;bottom:0;width:100%;">
                <el-pagination background @size-change="SizeChange" @current-change="CurrentChange" :current-page="currentPage" :page-sizes="[100, 200, 500, 1000]" :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </el-footer>
            <el-dialog v-model="dialogVisible" title="科目管理" width="35%" :before-close="handleClose">
                <el-form ref="form" :model="form" status-icon label-width="120px" :rules="rules">
                    <el-form-item label="科目名称" prop="SubjectName">
                        <el-input v-model="form.SubjectName"></el-input>
                    </el-form-item>
                    <el-form-item label="科目Icon" prop="SubjectIcon">
                        <el-upload class="avatar-uploader" :action="`${this.$CONFIG.API_URL}/File/UploadFileAvatar`" :show-file-list="false" :on-success="function (res, file,fileList) { return handleAvatarSuccess(res, file,fileList)} " :before-upload="beforeAvatarUpload" :headers="headers">
                            <img v-if="form.SubjectIcon" :src="form.SubjectIconUrl" class="avatar" />
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                        <!-- <el-input v-model="form.SubjectIcon" style=""></el-input> -->
                    </el-form-item>
                </el-form>
                <template #footer>
                    <span class="dialog-footer">
                        <el-button @click="handleClose">关闭</el-button>
                        <el-button type="primary" @click="btnAdd">确定</el-button>
                    </span>
                </template>
            </el-dialog>
        </el-main>
    </div>
</template>

<script>
import "@element-plus/icons";
export default {
    name: "SchoolSubject",
    data() {
        return {
            form: {
                Id: 0,
                SubjectName: "",
                SubjectIcon: "",
                SubjectIconUrl: "",
            },
            //输入验证
            rules: {
                Id: [{ required: true, message: "Id主键不能为空" }],
                SubjectName: [{ required: true, message: "科目名称不能为空" }],
                // SubjectIcon: [{ required: true, message: "科目Icon不能为空" }],
                // RemarkInfo: [{ required: true, message: "备注不能为空" }],
            },
            total: 400, //数据总量
            pagesize: 100, //分页大小
            currentPage: 1, //当前页数
            keyword: "", //关键字
            checkInput: "", //搜索内容
            searchStartTime: "", //搜索开始时间
            searchEndTime: "", //搜索结束时间
            dataTableSearchList: [], //列筛选结果
            dataTable: [], //显示数据
            multipleSelection: [], //多选结果
            dialogVisible: false, //是否显示弹出框
            headers: "", //请求headers
            heightStyle:720,
        };
    },
    mounted() {
        const that = this;
        this.$nextTick(function () {
            // 仅在整个视图都被渲染之后才会运行的代码
            let adminInfo = this.$TOOL.data.get("adminInfo");
            this.headers = {
                Authorization: "Bearer " + adminInfo.JwtToken,
            };
            window.onresize = () => {
                return (() => {
                    let heightStyle = window.innerHeight - 150;
                    that.heightStyle = heightStyle;
                })();
            };
            that.SearchPage();
        });
    },
    methods: {
        //分页搜索
        SearchPage() {
            var that = this;
            var json = {
                searchValue: "",
                pageNo: that.currentPage,
                pageSize: that.pagesize,
                searchBeginTime: that.searchStartTime,
                searchEndTime: that.searchEndTime,
                sortField: "",
                sortOrder: "",
                descStr: "",
            };
            if (that.checkInput.length > 0 && that.keyword != "") {
                var searchParameters = [],
                    input = that.keyword;
                for (let index = 0; index < that.checkInput.length; index++) {
                    const element = that.checkInput[index];
                    var searchParametersItem = {
                        field: element,
                        op: 8,
                        value: input,
                        orGroup: "",
                    };
                    searchParameters.push(searchParametersItem);
                }
                json.searchParameters = searchParameters;
            }
            that.$API.SchoolSubject.QueryByPage.post(json).then((res) => {
                that.dataTable = res.Rows;
                that.total = res.TotalRows;
            });
        },
        //重置数据
        resetForm() {
            this.form = {
                Id: 0,
                AppId: "",
                AppSecret: "",
                IsDefault: true,
                GID: "",
            };
        },
        //导出
        ExportExcel() {},
        //删除
        DeleteInfo() {
            var Ids = [];
            var that = this;
            var json = {};
            for (const key in this.multipleSelection) {
                if (Object.hasOwnProperty.call(this.multipleSelection, key)) {
                    const element = this.multipleSelection[key];
                    Ids.push(element.Id);
                }
            }
            this.$API.SchoolSubject.FakeDelete.delete(Ids).then((res) => {
                if (res.Code == 204) {
                    that.$notify({
                        title: "成功",
                        message: "操作成功！",
                        type: "success",
                    });
                    this.SearchPage();
                }
            });
        },
        //列筛选
        filterFunHandle(type, currentItem, index) {
            if (type === "allchecked") {
                // 全选
                var tmp = [];

                this.dataTableSearchList = tmp;
            } else if (type === "cancel") {
                // 取消全选
                this.dataTableSearchList = []; // 复选框置为空，全部不选择
            }
        },
        //多选结果
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        //编辑
        handleEdit(index, row) {
            var that = this;
            this.$API.SchoolSubject.QueryById.get(row.Id).then((res) => {
                that.form = res;
                
                that.dialogVisible = true;
            });
        },
        //删除
        handleDelete(index, row) {
            var Ids = [];
            Ids.push(row.Id);
            var that = this;
            this.$API.SchoolSubject.FakeDelete.delete(Ids).then((res) => {
                if (res.Code == 204) {
                    that.$notify({
                        title: "成功",
                        message: "操作成功！",
                        type: "success",
                    });
                    this.SearchPage();
                }
            });
        },
        //分页数修改
        SizeChange(val) {
            this.pagesize = val;
            this.SearchPage();
        },
        //当前页跳转
        CurrentChange(val) {
            this.currentPage = val;
            this.SearchPage();
        },
        //关闭弹出框
        handleClose() {
            this.dialogVisible = false;
            this.resetForm();
        },
        //新增或者修改
        btnAdd() {
            var that = this;
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    that.$API.SchoolSubject.AddOrUpdate.post(that.form).then(
                        (res) => {
                            if (res.Code == 204) {
                                that.$notify({
                                    title: "成功",
                                    message: "操作成功！",
                                    type: "success",
                                });
                                that.handleClose();
                                that.SearchPage();
                            }
                        }
                    );
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        //图片上传成功
        handleAvatarSuccess(res, file, fileList, obj) {
            console.log(res);
            this.form.SubjectIconUrl = URL.createObjectURL(file.raw);
            //this.imageUrl = URL.createObjectURL(file.raw);
            this.form.SubjectIcon = res.Data.Id;
            //this.$emit("handleAvatarSuccess", res);
        },
        //图片上传判断
        beforeAvatarUpload(file) {
            const fileType = file.type;
            const isImg = !(
                fileType != ".jpg" &&
                fileType != ".JPG" &&
                fileType != "image/jpeg" &&
                fileType != ".png" &&
                fileType != ".PNG" &&
                fileType != "image/png" &&
                fileType != ".gif" &&
                fileType != ".GIF" &&
                fileType != "image/gif"
            );
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isImg) {
                this.$message.error("上传头像图片只能是 JPG 格式!");
            }
            if (!isLt2M) {
                this.$message.error("上传头像图片大小不能超过 2MB!");
            }
            return isImg && isLt2M;
        },
    },
};
</script>

<style>
</style>